export const mealTypes = {
  BREAKFAST: 'breakfast',
  LUNCH: 'lunch',
  DINNER: 'dinner',
}

export const orderTypes = {
  REGULAR: 'regular',
  NOW: 'now',
  DELIVERY: 'delivery',
}

export const productTypes = {
  PLAN: 'plan',
  ADDON: 'addon',
  PROMO: 'promo',
  BUNDLE: 'bundle',
  REWARD: 'reward',
}

export const deliveryProviders = {
  ANYCARRY: 'AnyCarry',
  SHOP_MANUAL: 'ShopManual',
}

export const orderStatuses = {
  UNAVAILABLE: 'unavailable',
  AVAILABLE: 'available',
}

export const orderPhases = {
  CART: 'CART',
  UNCONFIRMED: 'UNCONFIRMED',
  CONFIRMED: 'CONFIRMED',
  READY_FOR_PICKUP: 'READY_FOR_PICKUP',
  DELIVERY_IN_PROGRESS: 'DELIVERY_IN_PROGRESS',
  COMPLETE: 'COMPLETE',
}

export const mealStatuses = {
  LOADING: 'loading',
  UNAVAILABLE: 'unavailable',
  AVAILABLE: 'available',
}

export const storePageTypes = {
  MEMBERSHIP: 'membership',
  ORDERING: 'ordering',
}

export const productDisplayTypes = {
  MEMBERSHIP_CARD: 'membership-card',
}

export const POTLUCK_NOW_BUFFER_MINUTES = 30
export const MIN_PASSWORD_LENGTH = 6

export const DEFAULT_LOCATION = {
  name: 'Osaka Station',
  address: '大阪府大阪市北区梅田３丁目１−1',
  googlePlaceId: 'ChIJC6fjlY3mAGARSshZ6CLIrhs',
  latitude: 34.702258,
  longitude: 135.497130,
  type: 'new-user-default',
}

export const DEFAULT_ZOOMED_LOCATION = {
  latitude: 34.702258,
  longitude: 135.497130,
  latitudeDelta: 0.0154,
  longitudeDelta: 0.007,
}

export const orderTypeLabels = {
  [orderTypes.REGULAR]: '予約でテイクアウト',
  [orderTypes.NOW]: 'いますぐテイクアウト',
  [orderTypes.DELIVERY]: 'デリバリー',
}

export const DEFAULT_OFFER_CODE = 'default'

export const DEFAULT_LOCATION_TYPE = 'new-user-default'

export const DEFAULT_SHOP_IMAGE = 'https://s3-ap-northeast-1.amazonaws.com/potluck-webapp/shopentry/fv_img.jpg'

export const RATINGS_COUNT_CUTOFF = 2

export const PAY_JP_PUBLIC_KEY_TEST = process.env.PAY_JP_PUBLIC_KEY_TEST || 'pk_test_8bb5e8c4c2be0a0b1248c37e'
export const PAY_JP_PUBLIC_KEY = process.env.PAY_JP_PUBLIC_KEY || 'pk_live_d1df5427c991030d37f1fcc7'

export const CC_FORMAT_MAP = {
  Visa: '•••• •••• •••• ',
  MasterCard: '•••• •••• •••• ',
  JCB: '•••• •••• •••• ',
  'American Express': '•••• •••••• •',
  'Diners Club': '•••• ••••••• ',
  Discover: '•••• •••• •••• ',
}

export const CC_IMAGE_MAP = {
  Visa: 'https://potluck-web.s3.ap-northeast-1.amazonaws.com/cards/visa.png',
  MasterCard: 'https://potluck-web.s3.ap-northeast-1.amazonaws.com/cards/mastercard.png',
  JCB: 'https://potluck-web.s3.ap-northeast-1.amazonaws.com/cards/jcb.png',
  'American Express': 'https://potluck-web.s3.ap-northeast-1.amazonaws.com/cards/americanExpress.png',
  'Diners Club': 'https://potluck-web.s3.ap-northeast-1.amazonaws.com/cards/dinersClub.png',
  Discover: 'https://potluck-web.s3.ap-northeast-1.amazonaws.com/cards/discover.png',
}

export default {
  TAX_PERCENT: 8,
  LAST_CALL: 10, // 10am
  FIRST_CALL: 17, // 5pm
  REVIEW_BUFFER: 15, // mins

  LUNCH_FIRST_CALL: { hour: 17, minute: 30 },
  LUNCH_LAST_CALL: { hour: 10, minute: 30 },

  DINNER_FIRST_CALL: { hour: 10, minute: 30 },
  DINNER_LAST_CALL: { hour: 17, minute: 30 },

  PICKUP_WINDOW_LENGTH: 15,
}
